import React, { useState } from "react";
import "./style.css";
import DartIcon from "./darticon.svg";
import { Video } from "./Video";
import { faVolumeMute, faVolumeHigh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Reels({}) {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };
  const [mutedVideo, setMutedVideo] = useState(true);
  const handleClickVideo = (event) => {
    // 👇️ toggle isActive state on click
    setMutedVideo((current) => !current);
  };
  const [mutedVideo2, setMutedVideo2] = useState(true);
  const handleClickVideo2 = (event) => {
    // 👇️ toggle isActive state on click
    setMutedVideo2((current) => !current);
  };
  const [mutedVideo3, setMutedVideo3] = useState(true);
  const handleClickVideo3 = (event) => {
    // 👇️ toggle isActive state on click
    setMutedVideo3((current) => !current);
  };
  const [mutedVideo4, setMutedVideo4] = useState(true);
  const handleClickVideo4 = (event) => {
    // 👇️ toggle isActive state on click
    setMutedVideo4((current) => !current);
  };

  //mute icon
  const [isActive, setIsActive] = useState(false);

  const handleClick = (event) => {
    // 👇️ toggle isActive state on click
    setIsActive((current) => !current);
  };

  return (
    <>
      <div className="instagram-reels">
        <div className="main-page-title">Follow Our İnstagram</div>
        <div className="faq-text">
          Follow us on Instagram to get up-to-date information on immigration,
          life and real estate in Turkey.{" "}
        </div>
        <div className="instagram-contact-button">
          <button className="primary-button">Contact Us</button>
        </div>

        <div className="instagram-reels-inner">
          <div className="container">
            <div className="instagram-reels-left">
              <div className="instagram-reels-title">
                Вам может быть интересно
              </div>
              <div className="instagram-reels-tabs">
                <div
                  className={
                    toggleState === 1
                      ? "instagram-reels-tab instagram-reels-tab-active"
                      : "instagram-reels-tab"
                  }
                  onClick={() => toggleTab(1)}
                >
                  <div className="instagram-reels-tab-left">
                    <img src={DartIcon} />
                    <span>01</span>
                  </div>
                  <div className="instagram-reels-tab-right">
                    <span>
                      Настоящая находка для инвестиций в Стамбуле! Уютная
                      Азиатская сторона, свежий воздух, пешая доступность моря.
                      Множество интернациональных детских садов, школ,
                      университетов и клиник в шаговой доступности. Цены от
                      200.000 USD. Подходит под ВНЖ и гражданство.{" "}
                    </span>
                  </div>
                </div>
                <div
                  className={
                    toggleState === 2
                      ? "instagram-reels-tab instagram-reels-tab-active"
                      : "instagram-reels-tab"
                  }
                  onClick={() => toggleTab(2)}
                >
                  <div className="instagram-reels-tab-left">
                    <img src={DartIcon} />
                    <span>02</span>
                  </div>
                  <div className="instagram-reels-tab-right">
                    <span>
                      Сочи или Анталия? Разбираем плюсы и минусы недвижимости в
                      курортном городе.
                    </span>
                  </div>
                </div>
                <div
                  className={
                    toggleState === 3
                      ? "instagram-reels-tab instagram-reels-tab-active"
                      : "instagram-reels-tab"
                  }
                  onClick={() => toggleTab(3)}
                >
                  <div className="instagram-reels-tab-left">
                    <img src={DartIcon} />
                    <span>03</span>
                  </div>
                  <div className="instagram-reels-tab-right">
                    <span>
                      Можно ли купить квартиру в новостройке в Турции за
                      30-40.000 $?{" "}
                    </span>
                  </div>
                </div>
                <div
                  className={
                    toggleState === 4
                      ? "instagram-reels-tab instagram-reels-tab-active"
                      : "instagram-reels-tab"
                  }
                  onClick={() => toggleTab(4)}
                >
                  <div className="instagram-reels-tab-left">
                    <img src={DartIcon} />
                    <span>04</span>
                  </div>
                  <div className="instagram-reels-tab-right">
                    <span>
                      Рустам Майер выбирает COSY Life в качестве надежного
                      агентства недвижимости и сопровождения иммиграции, а мы, в
                      свою очередь, высоко ценим такое доверие и делаем все,
                      чтобы его оправдать.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="instagram-reels-right">
              <img src="/images/phone.png" alt="" />
              <div
                className={
                  toggleState === 1
                    ? "phone-content phone-content-active"
                    : "phone-content"
                }
                onClick={() => {
                  handleClick();
                  handleClickVideo();
                }}
              >
                <div className={isActive ? "dn" : "dp"}>
                  <FontAwesomeIcon icon={faVolumeMute} />
                </div>
                <div className={isActive ? "dp" : "dn"}>
                  <FontAwesomeIcon icon={faVolumeHigh} />
                </div>

                <Video
                  src="/images/reels1.mp4"
                  id="myVideo1"
                  muted={mutedVideo}
                  autoplay={true}
                  loop={true}
                  controls={true}
                />
              </div>
              <div
                className={
                  toggleState === 2
                    ? "phone-content phone-content-active"
                    : "phone-content"
                }
                onClick={() => {
                  handleClick();
                  handleClickVideo2();
                }}
              >
                <div className={isActive ? "dn" : "dp"}>
                  <FontAwesomeIcon icon={faVolumeMute} />
                </div>
                <div className={isActive ? "dp" : "dn"}>
                  <FontAwesomeIcon icon={faVolumeHigh} />
                </div>
                <Video
                  src="/images/reels2.mp4"
                  id="myVideo1"
                  muted={mutedVideo2}
                  autoplay={true}
                  loop={true}
                  controls={true}
                />
              </div>
              <div
                className={
                  toggleState === 3
                    ? "phone-content phone-content-active"
                    : "phone-content"
                }
                onClick={() => {
                  handleClick();
                  handleClickVideo3();
                }}
              >
                <div className={isActive ? "dn" : "dp"}>
                  <FontAwesomeIcon icon={faVolumeMute} />
                </div>
                <div className={isActive ? "dp" : "dn"}>
                  <FontAwesomeIcon icon={faVolumeHigh} />
                </div>
                <Video
                  src="/images/reels3.mp4"
                  id="myVideo1"
                  muted={mutedVideo3}
                  autoplay={true}
                  loop={true}
                  controls={true}
                />
              </div>
              <div
                className={
                  toggleState === 4
                    ? "phone-content phone-content-active"
                    : "phone-content"
                }
                onClick={() => {
                  handleClick();
                  handleClickVideo4();
                }}
              >
                <div className={isActive ? "dn" : "dp"}>
                  <FontAwesomeIcon icon={faVolumeMute} />
                </div>
                <div className={isActive ? "dp" : "dn"}>
                  <FontAwesomeIcon icon={faVolumeHigh} />
                </div>
                <Video
                  src="/images/reels4.mp4"
                  id="myVideo1"
                  muted={mutedVideo4}
                  autoplay={true}
                  loop={true}
                  controls={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Reels;
