import React, { useState } from "react";
import "./style.css";
import { Video } from "./Video";



function FixedVideo() {
  const [opacity, setOpacity] = useState(false);
  function setFixed() {
    if (window.scrollY >= 2000) {
      setOpacity(true);
    }
    if (window.scrollY >= 7000) {
      setOpacity(false);
    }
    if (window.scrollY <= 2000) {
      setOpacity(false);
    } 
  }

  window.addEventListener("scroll", setFixed);

  const [isActive, setIsActive] = useState(false);

  const FixedVideo = document.getElementsByClassName("video-container");

  const handleClick = (event) => {
    // 👇️ toggle isActive state on click
    setIsActive((current) => !current);
  };

  const handleClick2 = (event) => {
    // 👇️ remove class on click
    event.FixedVideo.classList.remove("video-active");
  };

  const [mutedVideo, setMutedVideo] = useState(true);
  const handleClickVideo = (event) => {
    // 👇️ toggle isActive state on click
    setMutedVideo((current) => !current);
  };

  return (
    <>
      <div
        className={` ${
          opacity
            ? "video video-container video-active"
            : "video video-container"
        } ${isActive ? "video-big" : ""}`}
        onClick={() => {
          handleClick();
          handleClickVideo();
        }}
      >
        <Video
          src="/images/video.mp4"
          id="myVideo1"
          muted={mutedVideo}
          autoplay={true}
          loop={true}
        />
        <div class="kucult" onClick={handleClick2}>
          <div class="kck">X</div>
        </div>
        <a href="https://wa.me/+905393066910?text=Добрый%20день!%20Я%20интересуюсь%20покупкой%20недвижимости%20в%20Стамбуле." target="_blank" className="ara">
        Свяжитесь с нами
        </a>
      </div>
    </>
  );
}
export default FixedVideo;
