import React from "react";

//Page Content
import Header from "../src/components/header/header";
import Hero from "../src/components/hero/hero";
import OurProjects from "./components/ourProjects/ourProjects";
import OurProjectsList from "./components/ourProjectsList/ourProjectList";
import OurServices from "./components/ourServices/ourServices";
import AboutUs from "../src/components/aboutUs/aboutUs";
import Reels from "./components/reels/reels"
import FAQ from "../src/components/faq/faq";
import FindProperty from "../src/components/findProperty/findProperty";
import Footer from "../src/components/footer/footer";


//Redux
import { Provider, useDispatch } from "react-redux";
import { store } from "./store/store";
import ContactUsWrapper from "./components/contactUsWrapper";
import { UPDATE_MODAL } from "./store/actionsName";


// Widgets
import FixedVideo from "./components/fixedVideo/fixedVideo";
import Whatsapp from "./components/whatsapp/whatsapp";
import ScrollToTop from "./components/scrollToTop/scrollToTop";



const App = () => {
    const dispatch = useDispatch();

    const modalToggle = () => dispatch({type:UPDATE_MODAL})

  return (
    <>
        <Header modalToggle={modalToggle} />
        <Hero modalToggle={modalToggle} />
        <OurProjects />
        <OurProjectsList modalToggle={modalToggle} />
        <OurServices />
        <AboutUs modalToggle={modalToggle} />
        <Reels/>
        <FAQ />
        <FindProperty modalToggle={modalToggle} />
        <Footer />
        <ContactUsWrapper modalToggle={modalToggle} />
        <Whatsapp/>
        <FixedVideo/>
        <ScrollToTop/>
    </>
  );
};

export default App;
