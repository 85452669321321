import React, { useRef, useState } from "react";
import "./style.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import emailjs from "@emailjs/browser";

function ContactUs({ modalToggle }) {

  const [activePage, setActivePage] = useState(1);
  const [newModalActivePage, setNewModalActivePage] = useState(1);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [flat, setFlat] = useState("");
  const [villa, setVilla] = useState("");
  const [penthouse, setPenthouse] = useState("");
  const [allTypes, setAllTypes] = useState("");
  const [userTypes, setUserTypes] = useState(flat + villa + penthouse + allTypes);

  const onChangeFlat = (event) => {
    setFlat(event.target.value)
  };

  const onChangeVilla = (event) => {
    setVilla(event.target.value)
  };

  const onChangePentHouse = (event) => {
    setPenthouse(event.target.value)
  }

  const onChangeAllTypes = (event) => {
    setAllTypes(event.target.value)
  }

  const onChangeUserTypes = (event) => {
    setUserTypes(event.target.value)
  }

  const [closesea, setCloeseSea] = useState("");
  const [terrace, setTerrace] = useState("");
  const [garden, setGarden] = useState("");
  const [highFloor, setHighFloor] = useState("");
  const [asianSide, setAsianSide] = useState("");
  const [euSide, setEuSide] = useState("");
  const [userExtra, setAllExtra] = useState(closesea + terrace + garden + highFloor + asianSide + euSide);

  const onChangeCloseSea = (event) => {
    setCloeseSea(event.target.value)
  }
  const onChangeTerrace = (event) => {
    setTerrace(event.target.value)
  }
  const onChangeGarden = (event) => {
    setGarden(event.target.value)
  }
  const onChangeHighFloor = (event) => {
    setHighFloor(event.target.value)
  }
  const onChangeAsianSide = (event) => {
    setAsianSide(event.target.value)
  }
  const onChangeEuSide = (event) => {
    setEuSide(event.target.value)
  }
  const onChangeUserExtras = (event) => {
    setAllExtra(event.target.value)
  }

  const [firstOption, setFirstOption] = useState("");
  const [secondOption, setSecondOption] = useState("");
  const [thirdOption, setThirdOption] = useState("");
  const [fourthOption, setFourthOption] = useState("");
  const [priceOptions, setPriceOptions] = useState(firstOption + "," + secondOption + "," + thirdOption + "," + fourthOption);

  const onChangeFirstOption = (event) => {
    setFirstOption(event.target.value)
  }
  const onChangeSecondOption = (event) => {
    setSecondOption(event.target.value)
  }
  const onChangeThirdOption = (event) => {
    setThirdOption(event.target.value)
  }
  const onChangeFourthOption = (event) => {
    setFourthOption(event.target.value)
  }
  const onChangePriceOptions = (event) => {
    setPriceOptions(event.target.value)
  }

  const [investments, setInvestments] = useState("");
  const [forLife, setForLife] = useState("");
  const [rental, setRental] = useState("");
  const [commercial, setCommercial] = useState("");
  const [summer, setSummer] = useState("");
  const [userPurpose, setUserPurpose] = useState(investments + "," + forLife + "," + rental + "," + commercial + "," + summer);

  const onChangeInvestments = (event) => {
    setInvestments(event.target.value)
  }
  const onChangeForLife = (event) => {
    setForLife(event.target.value)
  }
  const onChangeRental = (event) => {
    setRental(event.target.value)
  }
  const onChangeCommercial = (event) => {
    setCommercial(event.target.value)
  }
  const onChangeSummer = (event) => {
    setSummer(event.target.value)
  }
  const onChangeUserPurpose = (event) => {
    setUserPurpose(event.target.value)
  }

  const [soon, setSoon] = useState("");
  const [threeMonths, setThreeMonths] = useState("");
  const [halfYear, setHalfYear] = useState("");
  const [info, setInfo] = useState("");
  const [userWhen, setUserWhen] = useState(soon + "," + threeMonths + "," + halfYear + "," + info);

  const onChangeSoon = (event) => {
    setSoon(event.target.value)
  }
  const onChangeThreeMonths = (event) => {
    setThreeMonths(event.target.value)
  }
  const onChangeHalfYear = (event) => {
    setHalfYear(event.target.value)
  }
  const onChangeInfo = (event) => {
    setInfo(event.target.value)
  }
  const onChangeUserWhen = (event) => {
    setUserWhen(event.target.value)
  }

  const form = useRef();
  const infoPopUp = document.getElementById("infoPopUp");

  const errorMessageId = document.getElementById("errorMessageId");

  const handleChangeName = (event) => {
    setName(event.target.value);
  };
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const bodyClassRemove = () => {
    // 👇️ add class to the body element
    document.body.classList.remove("modal-active");

    // 👇️ toggle class on the body element
    // document.body.classList.toggle('bg-salmon');
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (
      name.trim().length !== 0 &&
      email.trim().length !== 0 &&
      phoneNumber.trim().length !== 0 &&
      userTypes.trim().length !== null &&
      userExtra.trim().length !== null &&
      priceOptions.trim().length !== null &&
      userPurpose.trim().length !== null &&
      userWhen.trim().length !== null
    ) {
      document.body.classList.remove("modal-active");
      emailjs
        .sendForm(
          "service_e7tlsk6",
          "template_q74t02f",
          form.current,
          "_ls_Sjh7mPnmYs8Pu"
        )
        .then(
          (result) => {
            modalToggle();
          },
          (error) => {
            console.log(error.text);
          }
        );
    } else {
      errorMessageId.classList.add("error-message-active");
    }
  };

  const nextBtn = () => {
    if (newModalActivePage == 1) {
      setNewModalActivePage(2);
    }
    if (newModalActivePage == 2) {
      setNewModalActivePage(3);
      console.log(flat, villa, penthouse, allTypes)
    }
    if (newModalActivePage == 3) {
      setNewModalActivePage(4);
      console.log(flat, villa, penthouse, allTypes)
    }
    if (newModalActivePage == 4) {
      setNewModalActivePage(5);
    }
    if (newModalActivePage == 5) {
      setNewModalActivePage(6);
    }
    if (newModalActivePage == 6) {
      setActivePage(2);
    }
  };

  const backBtn = () => {
    if (newModalActivePage == 2) {
      setNewModalActivePage(1);
    }
    if (newModalActivePage == 3) {
      setNewModalActivePage(2);
    }
    if (newModalActivePage == 4) {
      setNewModalActivePage(3);
    }
    if (newModalActivePage == 5) {
      setNewModalActivePage(4);
    }
    if (newModalActivePage == 6) {
      setNewModalActivePage(5);
    }
  };

  const goContactUs = () => {
    setActivePage(2);
  };

  return (
    <>
      {activePage == 1 && (
        <>
          <div className="contact-us-wrapper">
            <div className="contact-us-closebtn" onClick={modalToggle}>
              <span>X</span>
            </div>
            <div className="new-modal">
              {newModalActivePage == 1 && (
                <>
                  <div className="new-modal-image">
                    <img
                      className="new-modal-big"
                      src="/images/newModalBig1.jpg"
                    />
                    <img
                      className="new-modal-small"
                      src="/images/newModalSmall1.jpg"
                    />
                  </div>
                  <div className="new-modal-contents">
                    <h2>
                      Ответьте на вопросы и получите каталог объектов,
                      соответствующий вашим целям.
                    </h2>
                    <h3>Это займет менее 1 минуты. </h3>
                    <div className="new-modal-btn-wrapper">
                      <button onClick={nextBtn}> Перейти к вопросам</button>
                      <button onClick={goContactUs}>Связаться с нам</button>
                    </div>
                  </div>
                </>
              )}
              {newModalActivePage == 2 && (
                <>
                  <div className="new-modal-image">
                    <img
                      className="new-modal-big"
                      src="/images/newModalBig2.jpg"
                    />
                    <img
                      className="new-modal-small"
                      src="/images/newModalSmall2.jpg"
                    />
                  </div>
                  <div className="new-modal-contents progress">
                    <h2>
                      Шаг 1 <strong>из 5</strong>
                    </h2>
                    <div className="progress-bar">
                      <span className="active-bar"></span>
                      <span className="bar"></span>
                      <span className="bar"></span>
                      <span className="bar"></span>
                      <span className="bar"></span>
                    </div>
                    <div className="new-modal-title">
                      <h2>Выберите тип недвижимости:</h2>
                    </div>
                    <form className="new-modal-form" ref={form}>
                      <div className="new-modal-flex">
                        <label className="type-answers">
                          <input
                            className="type"
                            type="checkbox"
                            value="Квартира"
                            name="type_flat"
                            checked={flat}
                            onChange={onChangeFlat}
                          />
                          <span className="type-answer-title">Квартира</span>
                        </label>
                        <label className="type-answers">
                          <input
                            className="type"
                            type="checkbox"
                            value="Дом/Вилла"
                            name="type_villa"
                            checked={villa}
                            onChange={onChangeVilla}
                          />
                          <span className="type-answer-title">Дом/Вилла</span>
                        </label>
                        <label className="type-answers">
                          <input
                            className="type"
                            type="checkbox"
                            value="Пентхаус"
                            name="type_penthouse"
                            checked={penthouse}
                            onChange={onChangePentHouse}
                          />
                          <span className="type-answer-title">Пентхаус</span>
                        </label>
                        <label className="type-answers">
                          <input
                            className="type"
                            type="checkbox"
                            value="Рассмотрю все"
                            name="type_all"
                            checked={allTypes}
                            onChange={onChangeAllTypes}
                          />
                          <span className="type-answer-title">
                            Рассмотрю все
                          </span>
                        </label>
                      </div>
                    </form>
                    <div className="buttons">
                      <button onClick={backBtn} className="back-btn">
                        Назад
                      </button>
                      <button onClick={nextBtn} className="next-btn">
                        Далее
                      </button>
                    </div>
                  </div>
                </>
              )}
              {newModalActivePage == 3 && (
                <>
                  <div className="new-modal-image">
                    <img
                      className="new-modal-big"
                      src="/images/newModalBig3.jpg"
                    />
                    <img
                      className="new-modal-small"
                      src="/images/newModalSmall3.jpg"
                    />
                  </div>
                  <div className="new-modal-contents progress">
                    <h2>
                      Шаг 2 <strong>из 5</strong>
                    </h2>
                    <div className="progress-bar">
                      <span className="bar"></span>
                      <span className="active-bar"></span>
                      <span className="bar"></span>
                      <span className="bar"></span>
                      <span className="bar"></span>
                    </div>
                    <div className="new-modal-title">
                      <h2>Дополнительные парламенты:</h2>
                    </div>
                    <form className="new-modal-form" ref={form}>
                      <div className="new-modal-flex">
                        <label className="type-answers">
                          <input
                            className="type"
                            type="checkbox"
                            name="extra_closesea"
                            value="Близко к морю"
                            checked={closesea}
                            onChange={onChangeCloseSea}
                          />
                          <span className="type-answer-title">
                            Близко к морю
                          </span>
                        </label>
                        <label className="type-answers">
                          <input
                            className="type"
                            type="checkbox"
                            name="extra_terrace"
                            value="Балкон/Терраса"
                            checked={terrace}
                            onChange={onChangeTerrace}
                          />
                          <span className="type-answer-title">
                            Балкон/Терраса
                          </span>
                        </label>
                        <label className="type-answers">
                          <input
                            className="type"
                            type="checkbox"
                            name="extra_garden"
                            value="С садом"
                            checked={garden}
                            onChange={onChangeGarden}
                          />
                          <span className="type-answer-title">С садом</span>
                        </label>
                        <label className="type-answers">
                          <input
                            className="type"
                            type="checkbox"
                            name="extra_highFloor"
                            value="Высокий этаж"
                            checked={highFloor}
                            onChange={onChangeHighFloor}
                          />
                          <span className="type-answer-title">
                            Высокий этаж
                          </span>
                        </label>
                      </div>
                      <div className="new-modal-flex">
                        <label className="type-answers">
                          <input
                            className="type"
                            type="checkbox"
                            name="extra_asianSide"
                            value="Азиатская сторона"
                            checked={asianSide}
                            onChange={onChangeAsianSide}
                          />
                          <span className="type-answer-title">
                            Азиатская сторона
                          </span>
                        </label>
                        <label className="type-answers">
                          <input
                            className="type"
                            type="checkbox"
                            name="extra_euSide"
                            value="Европейская сторона"
                            checked={euSide}
                            onChange={onChangeEuSide}
                          />
                          <span className="type-answer-title">
                            Европейская сторона
                          </span>
                        </label>
                      </div>
                    </form>
                    <div className="buttons">
                      <button onClick={backBtn} className="back-btn">
                        Назад
                      </button>
                      <button onClick={nextBtn} className="next-btn">
                        Далее
                      </button>
                    </div>
                  </div>
                </>
              )}
              {newModalActivePage == 4 && (
                <>
                  <div className="new-modal-image">
                    <img
                      className="new-modal-big"
                      src="/images/newModalBig4.jpg"
                    />
                    <img
                      className="new-modal-small"
                      src="/images/newModalSmall4.jpg"
                    />
                  </div>
                  <div className="new-modal-contents progress">
                    <h2>
                      Шаг 3 <strong>из 5</strong>
                    </h2>
                    <div className="progress-bar">
                      <span className="bar"></span>
                      <span className="bar"></span>
                      <span className="active-bar"></span>
                      <span className="bar"></span>
                      <span className="bar"></span>
                    </div>
                    <div className="new-modal-title">
                      <h2>Стоимость объекта недвижимости:</h2>
                    </div>
                    <form className="new-modal-form" ref={form}>
                      <div className="new-modal-flex">
                        <label className="type-answers">
                          <input
                            className="type"
                            type="checkbox"
                            name="price_150_390"
                            value="150.000 — 390.000 $"
                            checked={firstOption}
                            onChange={onChangeFirstOption}
                          />
                          <span className="type-answer-title">
                            150.000 — 390.000 $
                          </span>
                        </label>
                        <label className="type-answers">
                          <input
                            className="type"
                            type="checkbox"
                            name="price_400_800"
                            value="400.000 — 800.000 $"
                            checked={secondOption}
                            onChange={onChangeSecondOption}
                          />
                          <span className="type-answer-title">
                            400.000 — 800.000 $
                          </span>
                        </label>
                        <label className="type-answers">
                          <input
                            className="type"
                            type="checkbox"
                            name="price_800_1500"
                            value="800.000 - 1.500.000 $"
                            checked={thirdOption}
                            onChangr={onChangeThirdOption}
                          />
                          <span className="type-answer-title">
                            800.000 - 1.500.000 $
                          </span>
                        </label>
                        <label className="type-answers">
                          <input
                            className="type"
                            type="checkbox"
                            name="price_1500+"
                            value="от 1.500.000 $"
                            checked={fourthOption}
                            onChange={onChangeFourthOption}
                          />
                          <span className="type-answer-title">
                            от 1.500.000 ${" "}
                          </span>
                        </label>
                      </div>
                    </form>
                    <div className="buttons">
                      <button onClick={backBtn} className="back-btn">
                        Назад
                      </button>
                      <button onClick={nextBtn} className="next-btn">
                        Далее
                      </button>
                    </div>
                  </div>
                </>
              )}
              {newModalActivePage == 5 && (
                <>
                  <div className="new-modal-image">
                    <img
                      className="new-modal-big"
                      src="/images/newModalBig5.jpg"
                    />
                    <img
                      className="new-modal-small"
                      src="/images/newModalSmall5.jpg"
                    />
                  </div>
                  <div className="new-modal-contents progress">
                    <h2>
                      Шаг 4 <strong>из 5</strong>
                    </h2>
                    <div className="progress-bar">
                      <span className="bar"></span>
                      <span className="bar"></span>
                      <span className="bar"></span>
                      <span className="active-bar"></span>
                      <span className="bar"></span>
                    </div>
                    <div className="new-modal-title">
                      <h2> Цель покупки:</h2>
                    </div>
                    <form className="new-modal-form" ref={form}>
                      <div className="new-modal-flex">
                        <label className="type-answers">
                          <input
                            className="type"
                            type="checkbox"
                            name="purpose_investments"
                            value="Инвестиции"
                            checked={investments}
                            onChange={onChangeInvestments}
                          />
                          <span className="type-answer-title">Инвестиции</span>
                        </label>
                        <label className="type-answers">
                          <input
                            className="type"
                            type="checkbox"
                            name="purpose_forLife"
                            value="Для жизни"
                            checked={forLife}
                            onChange={onChangeForLife}
                          />
                          <span className="type-answer-title">Для жизни</span>
                        </label>
                        <label className="type-answers">
                          <input
                            className="type"
                            type="checkbox"
                            name="purpose_rental"
                            value="Сдача в аренду"
                            checked={rental}
                            onChange={onChangeRental}
                          />
                          <span className="type-answer-title">
                            Сдача в аренду
                          </span>
                        </label>
                        <label className="type-answers">
                          <input
                            className="type"
                            type="checkbox"
                            name="purpose_commercial"
                            value="Коммерческая недвижимость"
                            checked={commercial}
                            onChange={onChangeCommercial}
                          />
                          <span className="type-answer-title">
                            Коммерческая недвижимость
                          </span>
                        </label>
                      </div>
                      <div className="new-modal-flex">
                        <label className="type-answers">
                          <input
                            className="type"
                            type="checkbox"
                            name="purpose_summer"
                            value="Летний дом"
                            checked={summer}
                            onChange={onChangeSummer}
                          />
                          <span className="type-answer-title">Летний дом</span>
                        </label>
                      </div>
                    </form>
                    <div className="buttons">
                      <button onClick={backBtn} className="back-btn">
                        Назад
                      </button>
                      <button onClick={nextBtn} className="next-btn">
                        Далее
                      </button>
                    </div>
                  </div>
                </>
              )}
              {newModalActivePage == 6 && (
                <>
                  <div className="new-modal-image">
                    <img src="/images/newModalBig6.jpg" />
                  </div>
                  <div className="new-modal-contents progress">
                    <h2>
                      Шаг 5 <strong>из 5</strong>
                    </h2>
                    <div className="progress-bar">
                      <span className="bar"></span>
                      <span className="bar"></span>
                      <span className="bar"></span>
                      <span className="bar"></span>
                      <span className="active-bar"></span>
                    </div>
                    <div className="new-modal-title">
                      <h2> Когда планируете покупку?</h2>
                    </div>
                    <form className="new-modal-form" ref={form}>
                      <div className="new-modal-flex">
                        <label className="type-answers">
                          <input
                            className="type"
                            type="checkbox"
                            name="when_soon"
                            value="В ближайшее время"
                            checked={soon}
                            onChange={onChangeSoon}
                          />
                          <span className="type-answer-title">
                            В ближайшее время
                          </span>
                        </label>
                        <label className="type-answers">
                          <input
                            className="type"
                            type="checkbox"
                            name="when_threeMonths"
                            value="Ближайшие 3 месяца"
                            checked={threeMonths}
                            onChange={onChangeThreeMonths}
                          />
                          <span className="type-answer-title">
                            Ближайшие 3 месяца
                          </span>
                        </label>
                        <label className="type-answers">
                          <input
                            className="type"
                            type="checkbox"
                            name="when_halfYear"
                            value="В течение полугода"
                            checked={halfYear}
                            onChange={onChangeHalfYear}
                          />
                          <span className="type-answer-title">
                            В течение полугода
                          </span>
                        </label>
                        <label className="type-answers">
                          <input
                            className="type"
                            type="checkbox"
                            name="when_info"
                            value="Просто собираю информацию"
                            checked={info}
                            onChange={onChangeInfo}
                          />
                          <span className="type-answer-title">
                            Просто собираю информацию
                          </span>
                        </label>
                      </div>
                      <div className="new-modal-flex"></div>
                    </form>
                    <div className="buttons">
                      <button onClick={backBtn} className="back-btn">
                        Назад
                      </button>
                      <button onClick={nextBtn} className="next-btn">
                        Далее
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div
              className="contact-us-overlay"
              onClick={function (event) {
                modalToggle();
                bodyClassRemove();
              }}
            ></div>
          </div>
        </>
      )}
      {activePage == 2 && (
        <>
          <div className="contact-us-wrapper">
            <div className="contact-us-closebtn" onClick={modalToggle}>
              <span>X</span>
            </div>
            <div className="contact-us">
              <div className="contact-us-content">
                <div className="contact-us-title">Свяжитесь с нами </div>
                <div className="contact-us-text">
                  Чтобы найти место, соответствующее вашим целям и пожеланиям
                </div>
                <h2 id="errorMessageId" className="error-message">
                  Please fill in the blanks.
                </h2>
              </div>
              <form ref={form} onSubmit={sendEmail}>
                <input 
                  type="text"
                  name="user_types"
                  value={flat + "," + villa + "," + penthouse + "," + allTypes}
                  className="unvisibble-input"
                  onChange={onChangeUserTypes}
                />
                <input 
                  type="text"
                  name="user_extras"
                  value={closesea + "," + terrace + "," + garden + "," + highFloor + "," + asianSide + "," + euSide}
                  className="unvisibble-input"
                  onChange={onChangeUserExtras}
                />
                <input 
                  type="text"
                  name="user_prices"
                  value={firstOption + "," + secondOption + "," + thirdOption + "," + fourthOption}
                  className="unvisibble-input"
                  onChange={onChangePriceOptions}
                />
                <input 
                  type="text"
                  name="user_purpose"
                  value={investments + "," + forLife + "," + rental + "," + commercial + "," + summer}
                  className="unvisibble-input"
                  onChange={onChangeUserPurpose}
                />
                <input 
                  type="text"
                  name="user_when"
                  value={soon + "," + threeMonths + "," + halfYear + "," + info}
                  className="unvisibble-input"
                  onChange={onChangeUserWhen}
                />
                <label for="name">
                  Имя
                  <input
                    type="text"
                    name="user_name"
                    placeholder="Имя Фамилия"
                    onChange={handleChangeName}
                  />
                </label>
                <label for="email">
                  E-mail
                  <input
                    type="email"
                    name="user_email"
                    placeholder="Ваш e-mail "
                    onChange={handleChangeEmail}
                  />
                </label>
                <label for="phone">
                  Номер телефона
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    name="user_tel"
                    type="tel"
                    international
                    defaultCountry="RU"
                  />
                </label>
                <label>
                Как мы можем вам помочь?
                  <textarea
                    cols="5"
                    rows="10"
                    name="message"
                    placeholder="Ваше сообщение "
                  ></textarea>
                </label>
                <input
                  type="submit"
                  value="Отправить"
                  className="primary-button"
                ></input>
              </form>
            </div>
            <div id="infoPopUp" className="info-popUp">
              <div className="info-popUp-content">
                <div className="info-popUp-title">
                  Thank You For Reaching Out!
                </div>
                <div className="info-popUp-text">
                  We will get back to you as soon as possible.
                </div>
              </div>
              <span>✅</span>
            </div>
            <div
              className="contact-us-overlay"
              onClick={function (event) {
                modalToggle();
                bodyClassRemove();
              }}
            ></div>
          </div>
        </>
      )}
    </>
  );
}
export default ContactUs;
